import { template as template_1f5725ef423a4b3fb548cc17d055d08c } from "@ember/template-compiler";
const WelcomeHeader = template_1f5725ef423a4b3fb548cc17d055d08c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
