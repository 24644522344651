import { template as template_d2caab4dbaaf441c87ce8bccf5f7d95f } from "@ember/template-compiler";
const FKLabel = template_d2caab4dbaaf441c87ce8bccf5f7d95f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
