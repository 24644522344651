import { template as template_75065292c8994220b5743bbe4e3d4c23 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_75065292c8994220b5743bbe4e3d4c23(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
