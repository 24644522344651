import { template as template_e5e69e449d6c4f649d3c234a6fc21671 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e5e69e449d6c4f649d3c234a6fc21671(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
